export const GoogleLoginEN = {
  GoogleLogin: {
    exit: 'Exit',
    exit_and_remove_permissions: 'Exit and remove permissions',
    credential:
      'Your credentials are stored only at the time of creating the video, and are deleted immediately after, at most 1 hour. You can invalidate them at any time by accessing here and removing the connection with the Bornlogic application.',
    video_privacy: 'Video privacy',
    public: 'Public',
    privacy: 'Private',
    title: 'Video title',
    description: 'Description',
    upload: 'Upload the video to be sent:',
    selectVideo: 'Select Video',
    terms_and_conditions_part1: 'I agree with the',
    terms_and_conditions_part2: 'terms of use',
    send: 'Send Video',
  },
};

export const GoogleLoginPT = {
  GoogleLogin: {
    exit: 'Sair',
    exit_and_remove_permissions: 'Sair e remover permissões',
    credential:
      'Suas credenciais são armazenadas apenas no momento de criar o video, e são deletadas logo após, em no máximo 1 hora. Você pode invalidá-las a qualquer momento acessando aqui e removendo a conexão com o aplicativo da Bornlogic',
    video_privacy: 'Privacidade do vídeo',
    public: 'Público',
    privacy: 'Privado',
    title: 'Titulo do vídeo',
    description: 'Descrição',
    upload: 'Faça upload do vídeo a ser enviado:',
    selectVideo: 'Selecionar Vídeo',
    terms_and_conditions_part1: 'Concordo com os',
    terms_and_conditions_part2: 'termos de uso',
    send: 'Enviar Vídeo',
  },
};

export const GoogleLoginES = {
  GoogleLogin: {
    exit: 'Salir',
    exit_and_remove_permissions: 'Salir y eliminar permisos',
    credential:
      'Sus credenciales se almacenan solo en el momento de crear el video, y se eliminan inmediatamente después, en un máximo de 1 hora. Puede invalidarlas en cualquier momento accediendo aquí y eliminando la conexión con la aplicación de Bornlogic.',
    video_privacy: 'Privacidad del video',
    public: 'Público',
    privacy: 'Privado',
    title: 'Título del video',
    description: 'Descripción',
    upload: 'Suba el video a enviar:',
    selectVideo: 'Seleccionar Video',
    terms_and_conditions_part1: 'Estoy de acuerdo con los',
    terms_and_conditions_part2: 'términos de uso',
    send: 'Enviar Video',
  },
};
